import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router';
import app from './firebase';
import { AuthContext } from './Auth';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

const Login = ({ history }) => {
    const handleLogin = useCallback(async event => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        try {
            await app
                .auth()
                .signInWithEmailAndPassword(email.value, password.value);
            history.push("/");
        } catch (error) {
            alert(error);
        }
    }, [history]);

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to="/" />;
    }

    return (

        <>
            <div className="card-spacer"></div>
            <div className="card-spacer"></div>
            <Container>
                <Card className="text-right">
                    <div>
                        <h1>Bookshelf Tutorial</h1>
                        <form onSubmit={handleLogin}>
                            <p></p>
                            <input name="email" type="email" placeholder="Email" />
                            <input name="password" type="password" placeholder="Password" />
                            <p></p>
                            <button type="submit">Login In</button>
                        </form>
                    </div>
                </Card>
            </Container>
        </>

    );
};

export default withRouter(Login);