import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from './Auth';
import { useForm } from 'react-hook-form';
import FileSaver, { saveAs } from 'file-saver';

import app from './firebase';
import SiteNav from './Components/SiteNav';
import LoadingCard from './Components/LoadingCard';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const S3Bucket = () => {
	const { currentUser } = useContext(AuthContext);

	const [loadingImage, setLoadingImage] = useState(false);
	const [approveClicked, setApproveClicked] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [imageURL, setImageURL] = useState(undefined);
	const [savedImageURL, setSavedImageURL] = useState(undefined);

	const [saved, setSaved] = useState(true);
	const [saving, setSaving] = useState(false);

	const [fileStats, setFileStats] = useState(undefined);
	const [file, setFile] = useState(undefined);

	const { handleSubmit, register, watch } = useForm({});

	const readFile = async (file) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			let img = new Image();
			img.onload = () => {
				setFileStats({ width: img.width, height: img.height });
			};
			img.src = reader.result;
		};

		reader.readAsDataURL(file);
	};

	const typedFileName = watch('name');

	const onFileChange = async (e) => {
		setLoadingImage(true);
		const file = e.target.files[0];
		readFile(file);
		setFile(file);
		const storageRef = app.storage().ref();
		const fileRef = storageRef.child(`images/temp/temp`);
		await fileRef.put(file);
		const imageURL = await fileRef.getDownloadURL();
		setImageURL(imageURL);
		setLoadingImage(false);
		setSaved(false);
	};

	const handleSaveBase = async () => {
		if (imageURL) {
			setSaving(true);
			const s3URL = await compressToS3(imageURL, typedFileName);
			console.log(s3URL);
			// setImageURL(s3URL);
			setSaving(false);
			setSaved(true);
		}
	};

	const compressToS3 = async (url, fileName) => {
		const compress_images = app.functions().httpsCallable('compress_images');
		try {
			const { data: s3URL } = await compress_images({ url, fileName });
			return s3URL;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	return (
		<div className="App">
			<SiteNav user={currentUser.email} tag={`Upload Images to S3`} showPages={true} />
			<Container>
				<Card className="coin-full-card">
					<Card.Body className="coin-nft-heading">
						<Row>
							<Col className="text-left">Upload image to S3 Bucket</Col>
						</Row>
					</Card.Body>
					<Card.Body>
						<Row>
							<Col>
								{loadingImage && <Spinner className="text-center image-coin-review" animation="border" variant="primary" />}
								{!loadingImage && imageURL && <img className="image-coin-review" src={imageURL} alt="base" />}
							</Col>
							<Col>
								{fileStats && (
									<p>
										Width: {fileStats.width}
										<br></br> Height: {fileStats.height}
									</p>
								)}
							</Col>
						</Row>
						<Form.Group>
							<Row>
								<Col>
									<input className="form-control-file" type="file" onChange={(e) => onFileChange(e)} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control name="name" defaultValue="File Name" ref={register} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Button onClick={handleSaveBase} variant={`${saved ? 'primary' : 'warning'}`} type="submit">
										{saving ? 'Saving...' : saved ? 'Saved' : 'Approve Image For APP'}
									</Button>

									{savedImageURL && <p>{savedImageURL}</p>}
								</Col>
							</Row>
						</Form.Group>
						<hr />
					</Card.Body>
				</Card>
			</Container>
			<Modal
				show={modalShow}
				animation={false}
				onHide={() => {
					setModalShow(false);
					setApproveClicked(false);
				}}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body className="text-center">
					{approveClicked ? (
						<h3>
							<Spinner className="text-center" animation="grow" variant="info" />
						</h3>
					) : (
						<Button variant="primary" type="submit">
							YES DO IT!
						</Button>
					)}
					<div className="card-spacer"></div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default S3Bucket;
