import { useState, useCallback, useEffect } from 'react';
import { subclass, specials, specialDesc, hairColors, eyeColors, skinColors, costume } from './nftStats';

const ColorsToHex = (color) => {
	let hexCode = '#6d6d6d';
	switch (color) {
		case 'brown':
			hexCode = '#4c2721';
			break;
		case 'black':
			hexCode = '#000000';
			break;
		case 'blonde':
			hexCode = '#facb35';
			break;
		case 'yellow':
			hexCode = '#facb35';
			break;
		case 'silver':
			hexCode = '#ababab';
			break;
		case 'white':
			hexCode = '#b8b8b8';
			break;
		case 'orange':
			hexCode = '#e07a15';
			break;
		case 'peach':
			hexCode = '#d67e57';
			break;
		case 'red':
			hexCode = '#a72f14';
			break;
		case 'blue':
			hexCode = '#357a96';
			break;
		case 'pastel blue':
			hexCode = '#617a84';
			break;
		case 'purple':
			hexCode = '#73359a';
			break;
		case 'pastel purple':
			hexCode = '#7f698d';
			break;
		case 'pink':
			hexCode = '#da6ccf';
			break;
		case 'green':
			hexCode = '#4c9c2f';
			break;
		case 'pastel green':
			hexCode = '#68845b';
			break;
		case 'pale':
			hexCode = '#f2d2bd';
			break;
		case 'tanned':
			hexCode = '#be9885';
			break;
		case 'brown':
			hexCode = '#604238';
			break;
		case 'dark':
			hexCode = '#392526';
			break;
		case 'metalic':
			hexCode = '#6d8c9a';
			break;
		case 'plastic':
			hexCode = '#93a1a7';
			break;
	}

	return hexCode;
};
const useStatsUtils = () => {
	return [subclass, specials, specialDesc, hairColors, eyeColors, skinColors, ColorsToHex, costume];
};

export default useStatsUtils;
