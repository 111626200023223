import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';

import app from '../firebase';

const SiteNav = (props) => {
	let { pagenumber } = useParams();
	if (!pagenumber) pagenumber = 1;

	const history = useHistory();

	const handleSelect = (eventKey) => {
		history.push(`/page/${eventKey}`);
		history.go();
	};

	const handleSelectStats = (eventKey) => {
		history.push(`/stats/${eventKey}`);
		history.go();
	};

	const handleSelectImages = (eventKey) => {
		history.push(`/images/${eventKey}`);
		history.go();
	};

	const handleSelectItems = (eventKey) => {
		history.push(`/equipment/${eventKey}`);
		history.go();
	};

	return (
		<>
			<Navbar sticky="top" bg="dark" variant="dark">
				<Navbar.Brand href="/">Coin Baes &nbsp;&nbsp;&nbsp;&nbsp; </Navbar.Brand>

				{props.showPages ? (
					<Nav variant="pills" activeKey={pagenumber} onSelect={handleSelect}>
						<NavDropdown className="a-whitetext" title={`Rank Pages`} id="nav-dropdown">
							<NavDropdown.Item eventKey="1">Ranks 1-60</NavDropdown.Item>
							<NavDropdown.Item eventKey="2">Ranks 60-120</NavDropdown.Item>
							<NavDropdown.Item eventKey="3">Ranks 120-180</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				) : (
					<div></div>
				)}

				{props.showPages ? (
					<Nav variant="pills" activeKey={pagenumber} onSelect={handleSelectStats}>
						<NavDropdown className="a-whitetext" title={`Character Stats`} id="nav-dropdown">
							<NavDropdown.Item eventKey="1">Ranks 1-80</NavDropdown.Item>
							<NavDropdown.Item eventKey="2">Ranks 80-160</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				) : (
					<div></div>
				)}

				{props.showPages ? (
					<Nav variant="pills" activeKey={pagenumber} onSelect={handleSelectImages}>
						<NavDropdown className="a-whitetext" title={`Edit Images`} id="nav-dropdown">
							<NavDropdown.Item eventKey="1">Ranks 1-80</NavDropdown.Item>
							<NavDropdown.Item eventKey="2">Ranks 80-160</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				) : (
					<div></div>
				)}

				{props.showPages ? (
					<Nav variant="pills" activeKey={pagenumber} onSelect={handleSelectItems}>
						<NavDropdown className="a-whitetext" title={`Equipment`} id="nav-dropdown">
							<NavDropdown.Item eventKey="pets">Pets</NavDropdown.Item>
							<NavDropdown.Item eventKey="items">Items</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				) : (
					<div></div>
				)}

				<Navbar.Text>&nbsp;&nbsp;&nbsp;&nbsp;</Navbar.Text>
				<Button variant="secondary" onClick={() => history.push('/s3bucket')}>
					S3 Bucket
				</Button>

				<Navbar.Toggle />
				<Navbar.Collapse className="justify-content-end">
					<Navbar.Text>{props.tag}&nbsp;&nbsp;&nbsp;&nbsp;</Navbar.Text>
					<Button onClick={() => app.auth().signOut()} variant="info" size="sm">
						Sign out
					</Button>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default SiteNav;
