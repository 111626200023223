import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import app from './firebase';

import { AuthContext } from './Auth';

import SiteNav from './Components/SiteNav';
import CoinImages from './Components/CoinImages';
import EquipmentImages from './Components/EquipmentImages';
import LoadingCard from './Components/LoadingCard';

import useStatsUtils from './hooks/useStatsUtils';

import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Equipment() {
	let { equiptype } = useParams();
	console.log(equiptype);
	if (!equiptype) equiptype = 1;

	const { currentUser } = useContext(AuthContext);
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);

	const ref = app.firestore().collection('items');

	function getItems() {
		async function init() {
			const itemsPerPage = 1000;
			const itemsStartAt = equiptype === 'pets' ? 0 : 1001;

			setLoading(true);
			const _items = [];
			const snapshot = await ref.orderBy('id').startAt(itemsStartAt).limit(itemsPerPage).get();
			snapshot.forEach((doc) => {
				const itemObject = doc.data();
				_items.push(itemObject);
			});

			setItems(_items);
			setLoading(false);
		}

		init();
	}

	useEffect(() => {
		getItems();
	}, []);

	// RENDER

	const NavigationBar = <SiteNav user={currentUser.email} tag={`Equipment Images Page ${equiptype}`} showPages={true} />;
	if (loading) {
		return (
			<>
				{NavigationBar}
				<LoadingCard />
			</>
		);
	}

	return (
		<div className="App">
			{NavigationBar}

			<CardGroup>
				{items.map((item, index) => {
					return (
						<div key={index}>
							<EquipmentImages props={item} />
						</div>
					);
				})}
			</CardGroup>
		</div>
	);
}

export default Equipment;
