import React from 'react';

import date from 'date-and-time';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

const coin = (props) => {
	const dateString = date.format(new Date(props.timestamp), 'DD MMM YY');
	const link = `https://coinmarketcap.com/currencies/${props.name.replace(/\s+/g, '-')}/`;
	const refLink = props.NFT.reference_links;
	const imgLink = props.NFT.images.ss;
	let coinTags = <div></div>;
	let NFTProps = <div></div>;

	if (props.tags) {
		coinTags = (
			<Card.Body className="text-right card-top">
				{props.tags.map((tag, index) => (
					<Badge key={`${props.id}${index}`} variant="warning" pill>
						<small>{tag}</small>
					</Badge>
				))}
			</Card.Body>
		);
	}

	if (props.NFT.properties) {
		NFTProps = (
			<Card.Body className="text-left zero-left-padding">
				{props.NFT.properties.map((tag, index) => (
					<div key={`${props.id}${index}`}>
						<div className="coin-properties" variant="info">
							{tag}
						</div>
					</div>
				))}
			</Card.Body>
		);
	}

	return (
		<Container>
			<Card className="coin-full-card">
				<Card.Body className="text-right card-top coin-short">
					<Row>
						<Col className="text-left">
							{props.rank}. {props.symbol}
						</Col>
						<Col className="text-left">
							<img src={props.logo} alt="logo" width="24" height="24" />
						</Col>
						<Col className="text-left">{props.name}</Col>
						<Col>
							<Button variant="light" size="sm">
								<a href={link} target="_blank" rel="noreferrer">
									<small>external link</small>
								</a>
							</Button>
						</Col>
						<Col>
							<small className="text-muted">{props.category}</small>
						</Col>
						<Col>
							<small className="text-muted">{dateString}</small>
						</Col>
					</Row>
				</Card.Body>
				{coinTags}
				<Card.Body className="coin-nft-heading">
					<Row>
						<Col className="text-left">{props.NFT.name}</Col>
					</Row>
				</Card.Body>
				<Card.Body className="coin-short coin-body">
					<Row>
						<Col className="coin-preview text-right" xs>
							<img className="image-coin-thumbnail" src={imgLink} alt="coin" />
							<br></br>
							Artist: {props.NFT.artist ? props.NFT.artist : <div></div>}
						</Col>
						<Col className="text-left">{NFTProps}</Col>
					</Row>
				</Card.Body>
				<Card.Body className="coin-short card-top card-design-hints">
					<small>Ref Links: </small>{' '}
					{refLink && refLink.length > 0 ? (
						<Button variant="light" size="sm">
							<a href={refLink} target="_blank" rel="noreferrer">
								<small>external reference link</small>
							</a>
						</Button>
					) : (
						<div></div>
					)}
				</Card.Body>
				<Card.Body className="coin-short card-top card-design-hints">
					<small>Design Notes: </small> {props.NFT.design_hints}
				</Card.Body>
			</Card>
		</Container>
	);
};

export default coin;
