import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import app from './firebase';

import { AuthContext } from './Auth';

import SiteNav from './Components/SiteNav';
import Coin from './Components/Coin';
import LoadingCard from './Components/LoadingCard';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Home() {
	let { pagenumber } = useParams();
	if (!pagenumber) pagenumber = 1;

	const { currentUser } = useContext(AuthContext);
	const [coins, setCoins] = useState([]);
	const [loading, setLoading] = useState(false);

	const ref = app.firestore().collection('coins');
	const history = useHistory();

	function getCoins() {
		async function init() {
			const coinsPerPage = 60;
			const coinsStartAt = pagenumber === 1 ? 1 : coinsPerPage * pagenumber - coinsPerPage;

			setLoading(true);
			const _coins = [];
			// const snapshot = await ref.orderBy('rank', 'asc').limit(3).get();
			const snapshot = await ref.orderBy('rank').startAt(coinsStartAt).limit(coinsPerPage).get();
			snapshot.forEach((doc) => {
				const coinObject = doc.data();
				_coins.push(coinObject);
			});

			setCoins(_coins);
			setLoading(false);
		}

		init();
	}

	useEffect(() => {
		getCoins();
	}, []);

	// RENDER

	const NavigationBar = <SiteNav user={currentUser.email} tag={`Coin Rank Page ${pagenumber}`} showPages={true} />;
	if (loading) {
		return (
			<>
				{NavigationBar}
				<LoadingCard />
			</>
		);
	}

	return (
		<div className="App">
			{NavigationBar}
			<div className="card-spacer"></div>
			{coins.map((coin, index) => {
				return (
					<div key={index}>
						<Coin
							id={coin.id}
							rank={coin.rank}
							name={coin.name}
							symbol={coin.symbol}
							category={coin.category}
							timestamp={coin.creation_date}
							logo={coin.logo}
							tags={coin.tags}
							NFT={coin.nft[coin.nft.length - 1]}
						/>
						<Container>
							<Row>
								<Col className="text-left">
									<Button onClick={() => history.push(`/edit/${coin.id}`)} variant="secondary">
										Edit
									</Button>
									&nbsp;&nbsp;
								</Col>
								<Col></Col>
							</Row>
						</Container>
						<div className="card-spacer"></div>
					</div>
				);
			})}
		</div>
	);
}

export default Home;
