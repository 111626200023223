import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PieChart } from 'react-minimal-pie-chart';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

import app from '../firebase';
import useStatsUtils from '../hooks/useStatsUtils';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const updateStore = async (coinId, data) => {
	try {
		const docRef = app.firestore().collection('coins').doc(coinId.toString());
		await docRef.update({
			stats: data,
		});
	} catch (e) {
		console.log(e);
	}
};

const CoinStats = ({ props }) => {
	const [subclass, specials, specialDesc, hairColors, eyeColors, skinColors, ColorsToHex, costume] = useStatsUtils();

	const [stats, setStats] = useState({
		class: 'CLASS',
		subclass: 'SUBCLASS',
		special1: 'NONE',
		special2: 'NONE',
		hair: 'NONE',
		eyes: 'NONE',
		skin: 'NONE',
		costume: 'NONE',
	});

	const [loading, setLoading] = useState(false);
	const [saved, setSaved] = useState(true);

	const [modalSubclassShow, setModalSubclassShow] = useState(false);
	const [modalSpecial1Show, setModalSpecial1Show] = useState(false);
	const [modalSpecial2Show, setModalSpecial2Show] = useState(false);

	const [modalHairColorsShow, setModalHairColorsShow] = useState(false);
	const [modalEyeColorsShow, setModalEyeColorsShow] = useState(false);
	const [modalSkinColorsShow, setModalSkinColorsShow] = useState(false);

	const [modalCostumeShow, setModalCostumeShow] = useState(false);

	const [baseImageURL, setBaseImageURL] = useState(undefined);

	const { register, getValues } = useForm({});

	const getBaseImage = async (props) => {
		if (props.images) {
			// console.log(props.images);
			if (props.images.fb_base) {
				setBaseImageURL(props.images.fb_base);
			}
		} else {
			setBaseImageURL(props.nft[props.nft.length - 1].images.ss);
		}
	};

	useEffect(() => {
		if (props) {
			getBaseImage(props);
		}
		if (props.stats) {
			setStats(props.stats);
		} else {
		}
	}, []);

	if (!props) {
		return <div>Loading</div>;
	}

	const saveChanges = async () => {
		if (stats.hair === undefined) {
			stats.hair = 'brown';
		}
		if (stats.eyes === undefined) {
			stats.eyes = 'brown';
		}
		if (stats.skin === undefined) {
			stats.skin = 'pale';
		}
		if (stats.costume === undefined) {
			stats.costume = 'uniform';
		}

		const statsData = {
			class: stats.class,
			subclass: stats.subclass,
			special1: typeof stats.special1 === 'number' ? stats.special1 : specials[stats.class].indexOf(stats.special1),
			special2: stats.special2,
			hair: stats.hair,
			eyes: stats.eyes,
			skin: stats.skin,
			costume: stats.costume,
		};

		setLoading(true);
		await updateStore(props.id, statsData);
		setLoading(false);

		setSaved(true);
	};

	const unsaved = () => {
		setSaved(false);
	};

	const toggleSubclass = () => {
		setModalSubclassShow(!modalSubclassShow);
	};

	const toggleHairColors = () => {
		setModalHairColorsShow(!modalHairColorsShow);
	};

	const toggleEyeColors = () => {
		setModalEyeColorsShow(!modalEyeColorsShow);
	};

	const toggleSkinColors = () => {
		setModalSkinColorsShow(!modalSkinColorsShow);
	};

	const toggleCostume = () => {
		setModalCostumeShow(!modalCostumeShow);
	};

	const handleSetSubclass = (coinSubclass) => {
		toggleSubclass();
		let coinclass = 'CLASS';

		switch (coinSubclass) {
			case subclass.warrior[0]:
			case subclass.warrior[1]:
			case subclass.warrior[2]:
			case subclass.warrior[3]:
				coinclass = 'warrior';
				break;
			case subclass.mage[0]:
			case subclass.mage[1]:
			case subclass.mage[2]:
			case subclass.mage[3]:
				coinclass = 'mage';
				break;
			case subclass.rogue[0]:
			case subclass.rogue[1]:
			case subclass.rogue[2]:
			case subclass.rogue[3]:
				coinclass = 'rogue';
				break;
			default:
				break;
		}

		if (coinclass === stats.class) {
			// class did not cahnge
			setStats((prevState) => {
				return { ...prevState, subclass: coinSubclass, class: coinclass };
			});
		} else {
			setStats((prevState) => {
				return { ...prevState, subclass: coinSubclass, class: coinclass, special1: 'NONE' };
			});
		}
		unsaved();
	};

	const toggleSpecial1 = () => {
		setModalSpecial1Show(!modalSpecial1Show);
	};

	const handleSetSpecial1 = (coinSpecial1) => {
		toggleSpecial1();
		setStats((prevState) => {
			return { ...prevState, special1: coinSpecial1 };
		});
		unsaved();
	};

	const handleSetColorTrait = (trait, color, toggle) => {
		toggle();
		setStats((prevState) => {
			return { ...prevState, [trait]: color };
		});
		unsaved();
	};

	const toggleSpecial2 = () => {
		setModalSpecial2Show(!modalSpecial2Show);
	};

	const handleSetSpecial2 = () => {
		toggleSpecial2();
		let coinSpecial2 = getValues('special2').replace(/\s+/g, '-').toLowerCase();

		setStats((prevState) => {
			return { ...prevState, special2: coinSpecial2 };
		});
		unsaved();
	};

	const ClassesButton = ({ stat, toggle }) => {
		return (
			<button onClick={toggle} className={`coin-properties-button stats-${stat}`} variant="info">
				{stat}
			</button>
		);
	};

	const Specials1Button = ({ stat, toggle }) => {
		return (
			<button onClick={toggle} className={`coin-properties-button stats-${stats.subclass}`} variant="info">
				{typeof stat === 'number' ? `(${stat}) ${specials[stats.class][stat]}` : stat === 'NONE' ? stat : `(${specials[stats.class].indexOf(stat)}) ${stat}`}
			</button>
		);
	};

	const Specials2Button = ({ stat, toggle }) => {
		return (
			<button onClick={toggle} className={`coin-properties-button stats-${stats.subclass}`} variant="info">
				{stat}
			</button>
		);
	};

	const TraitsButton = ({ stat, toggle, trait }) => {
		return (
			<button onClick={toggle} style={{ backgroundColor: ColorsToHex(stat) }} className={`coin-properties-button`} variant="info">
				{`${trait} ${stat}`}
			</button>
		);
	};

	const classType = () => {
		let className = 'coin-stats-top';
		switch (stats.class) {
			case 'warrior':
				return (className += ' stats-warrior');

			case 'mage':
				return (className += ' stats-mage');

			case 'rogue':
				return (className += ' stats-rogue');

			default:
				return className;
		}
	};

	return (
		<Card className={`coin-stats-card`}>
			<Card.Body className={classType()}>
				<Row>
					<Col className="text-left">
						{props.rank}. {props.symbol}. {props.name}
					</Col>
					<Col className="text-right">
						<img src={props.logo} alt="logo" width="20" height="20" />
					</Col>
				</Row>
			</Card.Body>
			<Card.Body className={`coin-short coin-body-stats ${classType()}`}>
				<div className="coin-stats-chart-container">
					<img width="200" height="200" className="image-coin-stats" src={baseImageURL} alt="coin" />

					<div className={classType()}>
						<h5 className="text-center">{stats.class.toUpperCase()}</h5>
					</div>
				</div>
				<Row>
					<Card.Body>
						<div>
							<ClassesButton toggle={toggleSubclass} stat={stats.subclass} />
							<Specials1Button toggle={toggleSpecial1} stat={stats.special1} />
							<Specials2Button toggle={toggleSpecial2} stat={stats.special2} />
							<TraitsButton toggle={toggleHairColors} trait="Hair:" stat={stats.hair} />
							<TraitsButton toggle={toggleEyeColors} trait="Eyes:" stat={stats.eyes} />
							<TraitsButton toggle={toggleSkinColors} trait="Skin:" stat={stats.skin} />
							<TraitsButton toggle={toggleCostume} trait="Costume:" stat={stats.costume} />
						</div>
						<Button onClick={saveChanges} variant={`${saved ? 'dark' : 'info'}`}>
							{saved ? 'Saved ' : 'Unsaved Changes '}
							{loading && <Spinner className="text-center" size="sm" animation="border" variant="light" />}
						</Button>
					</Card.Body>
				</Row>
			</Card.Body>

			<Modal show={modalSubclassShow} animation={false} onHide={toggleSubclass} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{props.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Warrior</h4>
					{subclass.warrior.map((item, index) => (
						<button key={index} onClick={() => handleSetSubclass(item)} className={`coin-properties-button-small stats-${item}`} variant="info">
							{item}
						</button>
					))}
					<hr></hr>
					<h4>Mage</h4>
					{subclass.mage.map((item, index) => (
						<button key={index} onClick={() => handleSetSubclass(item)} className={`coin-properties-button-small stats-${item}`} variant="info">
							{item}
						</button>
					))}
					<hr></hr>
					<h4>Rogue</h4>
					{subclass.rogue.map((item, index) => (
						<button key={index} onClick={() => handleSetSubclass(item)} className={`coin-properties-button-small stats-${item}`} variant="info">
							{item}
						</button>
					))}
				</Modal.Body>
			</Modal>

			<Modal show={modalSpecial1Show} animation={false} onHide={toggleSpecial1} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{props.name}: Special 1</Modal.Title>
				</Modal.Header>
				{stats.class === 'warrior' && (
					<Modal.Body>
						{specials.warrior.map((item, index) => (
							<button key={index} onClick={() => handleSetSpecial1(item)} className="coin-properties-button-small" variant="info">
								{item}
								<br></br>
								<small>{specialDesc.warrior[index]}</small>
							</button>
						))}
					</Modal.Body>
				)}

				{stats.class === 'mage' && (
					<Modal.Body>
						{specials.mage.map((item, index) => (
							<button key={index} onClick={() => handleSetSpecial1(item)} className="coin-properties-button-small" variant="info">
								{item}
								<br></br>
								<small>{specialDesc.mage[index]}</small>
							</button>
						))}
					</Modal.Body>
				)}

				{stats.class === 'rogue' && (
					<Modal.Body>
						{specials.rogue.map((item, index) => (
							<button key={index} onClick={() => handleSetSpecial1(item)} className="coin-properties-button-small" variant="info">
								{item}
								<br></br>
								<small>{specialDesc.rogue[index]}</small>
							</button>
						))}
					</Modal.Body>
				)}
			</Modal>

			<Modal show={modalSpecial2Show} animation={false} onHide={toggleSpecial2} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{props.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete="false" onSubmit={handleSetSpecial2}>
						<Form.Group>
							<Form.Label className="Modal-form-label">Enter meme or funny Special</Form.Label>
							<Form.Control autoFocus={true} name="special2" autoComplete="false" ref={register} />
						</Form.Group>
						<button type="button" onClick={handleSetSpecial2} className="coin-properties-button-small" variant="info">
							Save
						</button>
					</form>
				</Modal.Body>
			</Modal>

			<Modal show={modalHairColorsShow} animation={false} onHide={toggleHairColors} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{props.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Hair</h4>
					{hairColors.map((item, index) => (
						<button
							style={{ backgroundColor: ColorsToHex(item) }}
							key={index}
							onClick={() => handleSetColorTrait('hair', item, toggleHairColors)}
							className={`coin-properties-button-small stats-${item}`}
							variant="info"
						>
							{item}
						</button>
					))}
				</Modal.Body>
			</Modal>

			<Modal show={modalEyeColorsShow} animation={false} onHide={toggleEyeColors} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{props.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Eyes</h4>
					{eyeColors.map((item, index) => (
						<button
							style={{ backgroundColor: ColorsToHex(item) }}
							key={index}
							onClick={() => handleSetColorTrait('eyes', item, toggleEyeColors)}
							className={`coin-properties-button-small stats-${item}`}
							variant="info"
						>
							{item}
						</button>
					))}
				</Modal.Body>
			</Modal>

			<Modal show={modalSkinColorsShow} animation={false} onHide={toggleSkinColors} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{props.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Skin</h4>
					{skinColors.map((item, index) => (
						<button
							style={{ backgroundColor: ColorsToHex(item) }}
							key={index}
							onClick={() => handleSetColorTrait('skin', item, toggleSkinColors)}
							className={`coin-properties-button-small stats-${item}`}
							variant="info"
						>
							{item}
						</button>
					))}
				</Modal.Body>
			</Modal>
			<Modal show={modalCostumeShow} animation={false} onHide={toggleCostume} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{props.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Costume</h4>
					{costume.map((item, index) => (
						<button key={index} onClick={() => handleSetColorTrait('costume', item, toggleCostume)} className={`coin-properties-button-small`} variant="warning">
							{item}
						</button>
					))}
				</Modal.Body>
			</Modal>
		</Card>
	);
};

export default CoinStats;
