import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import app from './firebase';

import { AuthContext } from './Auth';

import SiteNav from './Components/SiteNav';
import CoinStats from './Components/CoinStats';
import LoadingCard from './Components/LoadingCard';

import useStatsUtils from './hooks/useStatsUtils';

import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Stats() {
	let { pagenumber } = useParams();
	if (!pagenumber) pagenumber = 1;

	const { currentUser } = useContext(AuthContext);
	const [subclass] = useStatsUtils();
	const [coins, setCoins] = useState([]);
	const [loading, setLoading] = useState(false);
	const [statsCoverage, setStatsCoverage] = useState({});
	const [show, setShow] = useState(false);

	const [classCoverageData, setClassCoverageData] = useState([
		{ title: 'warrior', value: 30, color: '#0066aa' },
		{ title: 'mage', value: 40, color: '#e7008b' },
		{ title: 'rogue', value: 30, color: '#05dd66' },
	]);

	const [warriorCoverageData, setWarriorCoverageData] = useState([
		{ title: 'paladin', value: 30, color: '#0a5ce0' },
		{ title: 'knight', value: 40, color: '#133d75' },
		{ title: 'dark-knight', value: 30, color: '#211e3d' },
		{ title: 'dragoon', value: 30, color: '#3400c4' },
	]);

	const [mageCoverageData, setMageCoverageData] = useState([
		{ title: 'sorcerer', value: 30, color: '#f52525' },
		{ title: 'summoner', value: 40, color: '#b6253b' },
		{ title: 'cleric', value: 30, color: '#eb33cc' },
		{ title: 'druid', value: 30, color: '#94008d' },
	]);

	const [rogueCoverageData, setRogueCoverageData] = useState([
		{ title: 'ranger', value: 30, color: '#1eac0e' },
		{ title: 'engineer', value: 40, color: '#00926e' },
		{ title: 'berserker', value: 30, color: '#004244' },
		{ title: 'monk', value: 30, color: '#007a83' },
	]);

	const ref = app.firestore().collection('coins');

	function getCoins() {
		async function init() {
			const coinsPerPage = 80;
			const coinsStartAt = pagenumber === 1 ? 1 : coinsPerPage * pagenumber - coinsPerPage;

			setLoading(true);
			const _coins = [];
			const snapshot = await ref.orderBy('rank').startAt(coinsStartAt).limit(coinsPerPage).get();
			snapshot.forEach((doc) => {
				const coinObject = doc.data();
				_coins.push(coinObject);
			});

			setCoins(_coins);
			setLoading(false);
		}

		init();
	}

	useEffect(() => {
		getCoins();
	}, []);

	useEffect(() => {
		if (coins && coins.length > 0) {
			let coverage = {
				warrior: [0, 0, 0, 0],
				mage: [0, 0, 0, 0],
				rogue: [0, 0, 0, 0],
			};
			let warrior = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			let mage = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			let rogue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			coins.forEach((coin) => {
				if (coin.stats && coin.stats.class === 'warrior') {
					for (let i = 0; i < subclass.warrior.length; i++) {
						if (subclass.warrior[i] === coin.stats.subclass) {
							coverage.warrior[i]++;
						}
					}
					warrior[coin.stats.special1]++;
				}
				if (coin.stats && coin.stats.class === 'mage') {
					for (let i = 0; i < subclass.mage.length; i++) {
						if (subclass.mage[i] === coin.stats.subclass) {
							coverage.mage[i]++;
						}
					}
					mage[coin.stats.special1]++;
				}
				if (coin.stats && coin.stats.class === 'rogue') {
					for (let i = 0; i < subclass.rogue.length; i++) {
						if (subclass.rogue[i] === coin.stats.subclass) {
							coverage.rogue[i]++;
						}
					}
					rogue[coin.stats.special1]++;
				}
			});
			setStatsCoverage({ ...coverage, special1: { warrior, mage, rogue } });
		}
	}, [coins]);

	useEffect(() => {
		if (statsCoverage && statsCoverage.warrior && statsCoverage.mage && statsCoverage.rogue) {
			const data = classCoverageData;
			const reducer = (acc, value) => acc + value;
			data[0].value = statsCoverage.warrior.reduce(reducer);
			data[1].value = statsCoverage.mage.reduce(reducer);
			data[2].value = statsCoverage.rogue.reduce(reducer);
			setClassCoverageData(data);
		}
		if (statsCoverage && statsCoverage.warrior) {
			const data = warriorCoverageData;
			data[0].value = parseInt(statsCoverage.warrior[0]);
			data[1].value = parseInt(statsCoverage.warrior[1]);
			data[2].value = parseInt(statsCoverage.warrior[2]);
			data[3].value = parseInt(statsCoverage.warrior[3]);
			setWarriorCoverageData(data);
		}
		if (statsCoverage && statsCoverage.mage) {
			const data = mageCoverageData;
			data[0].value = parseInt(statsCoverage.mage[0]);
			data[1].value = parseInt(statsCoverage.mage[1]);
			data[2].value = parseInt(statsCoverage.mage[2]);
			data[3].value = parseInt(statsCoverage.mage[3]);
			setMageCoverageData(data);
		}
		if (statsCoverage && statsCoverage.rogue) {
			const data = rogueCoverageData;
			data[0].value = parseInt(statsCoverage.rogue[0]);
			data[1].value = parseInt(statsCoverage.rogue[1]);
			data[2].value = parseInt(statsCoverage.rogue[2]);
			data[3].value = parseInt(statsCoverage.rogue[3]);
			setRogueCoverageData(data);
		}
	}, [statsCoverage]);

	useEffect(() => {
		console.log(statsCoverage);
	}, [statsCoverage]);

	// RENDER

	const NavigationBar = <SiteNav user={currentUser.email} tag={`Coin Stats Page ${pagenumber}`} showPages={true} />;
	if (loading) {
		return (
			<>
				{NavigationBar}
				<LoadingCard />
			</>
		);
	}

	return (
		<div className="App">
			{NavigationBar}

			<Alert show={show} transition={false} variant="secondary" className="stats-coverage">
				<Row>
					<Col>
						<div className="stats-coverage-chart">
							All Classes
							<br></br>
							<PieChart
								radius={50}
								center={[50, 50]}
								lineWidth={30}
								label={({ dataEntry }) => dataEntry.value}
								labelStyle={(index) => ({
									fill: 'black',
									fontSize: '8px',
									fontFamily: 'sans',
									fontWeight: 'bold',
								})}
								labelPosition={65}
								data={classCoverageData}
							/>
						</div>
					</Col>
					<Col>
						<div className="stats-coverage-chart">
							Warrior:
							<br></br>
							<PieChart
								radius={50}
								center={[50, 50]}
								lineWidth={30}
								label={({ dataEntry }) => dataEntry.value}
								labelStyle={(index) => ({
									fill: 'black',
									fontSize: '8px',
									fontFamily: 'sans',
									fontWeight: 'bold',
								})}
								labelPosition={65}
								data={warriorCoverageData}
							/>
						</div>
					</Col>
					<Col>
						<div className="stats-coverage-chart">
							Mage:
							<br></br>
							<PieChart
								radius={50}
								center={[50, 50]}
								lineWidth={30}
								label={({ dataEntry }) => dataEntry.value}
								labelStyle={(index) => ({
									fill: 'black',
									fontSize: '8px',
									fontFamily: 'sans',
									fontWeight: 'bold',
								})}
								labelPosition={65}
								data={mageCoverageData}
							/>
						</div>
					</Col>
					<Col>
						<div className="stats-coverage-chart">
							Rogue:
							<br></br>
							<PieChart
								radius={50}
								center={[50, 50]}
								lineWidth={30}
								label={({ dataEntry }) => dataEntry.value}
								labelStyle={(index) => ({
									fill: 'black',
									fontSize: '8px',
									fontFamily: 'sans',
									fontWeight: 'bold',
								})}
								labelPosition={65}
								data={rogueCoverageData}
							/>
						</div>
					</Col>
				</Row>
				<br></br>
				<hr></hr>
				<h5>Stats out of 80 characters</h5>
				{statsCoverage && statsCoverage.special1 && statsCoverage.special1.warrior && (
					<>
						<p>
							Number Of:
							<br></br>
							Paladin: {statsCoverage.warrior[0]}
							<br></br>
							Knight: {statsCoverage.warrior[1]}
							<br></br>
							Dark-Knight: {statsCoverage.warrior[2]}
							<br></br>
							Dragoon: {statsCoverage.warrior[3]}
							<br></br>
							Warrior Special counts: {statsCoverage.special1.warrior.toString()}
						</p>
					</>
				)}
				{statsCoverage && statsCoverage.special1 && statsCoverage.special1.mage && (
					<>
						<p>
							Number Of:
							<br></br>
							Sorcerer: {statsCoverage.mage[0]}
							<br></br>
							Summoner: {statsCoverage.mage[1]}
							<br></br>
							Cleric: {statsCoverage.mage[2]}
							<br></br>
							Druid: {statsCoverage.mage[3]}
							<br></br>
							Mage Special counts: {statsCoverage.special1.mage.toString()}
						</p>
					</>
				)}
				{statsCoverage && statsCoverage.special1 && statsCoverage.special1.rogue && (
					<>
						<p>
							Number Of:
							<br></br>
							Ranger: {statsCoverage.rogue[0]}
							<br></br>
							Berserker: {statsCoverage.rogue[1]}
							<br></br>
							Assassin: {statsCoverage.rogue[2]}
							<br></br>
							Monk: {statsCoverage.rogue[3]}
							<br></br>
							Rogue Special counts: {statsCoverage.special1.rogue.toString()}
						</p>
					</>
				)}

				<div className="d-flex justify-content-end">
					<Button onClick={() => setShow(false)} variant="outline-success">
						Hide Coverage
					</Button>
				</div>
			</Alert>
			{!show && (
				<Button variant="warning" className="stats-coverage" onClick={() => setShow(true)}>
					Show Coverage
				</Button>
			)}
			<hr></hr>
			<CardGroup>
				{coins.map((coin, index) => {
					return (
						<div key={index}>
							<CoinStats props={coin} />
						</div>
					);
				})}
			</CardGroup>
		</div>
	);
}

export default Stats;
