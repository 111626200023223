import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../Auth';
import FileSaver, { saveAs } from 'file-saver';

import Cropper from 'react-easy-crop';
import RangeSlider from 'react-bootstrap-range-slider';
import getCroppedImg, { uploadCroppedImg } from './CroppedImage';

import app from '../firebase';
import SiteNav from './SiteNav';
import LoadingCard from './LoadingCard';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Spinner from 'react-bootstrap/Spinner';

const updateStore = async (itemid, data) => {
	try {
		const docRef = app.firestore().collection('items').doc(itemid.toString());
		const doc = await docRef.get();
		const docData = await doc.data();

		let images = docData.images;
		images = {
			...images,
			...data,
		};

		await docRef.update({
			images,
		});
	} catch (e) {
		console.log(e);
	}
};

const EquipmentReview = () => {
	const { itemid } = useParams();
	const { currentUser } = useContext(AuthContext);

	const [item, setItem] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [loadingImage, setLoadingImage] = useState(true);
	const [baseImageURL, setBaseImageURL] = useState(undefined);
	const [firebaseImageURL, setFirebaseImageURL] = useState(undefined);

	const [savedBase, setSavedBase] = useState(true);
	const [savingBase, setSavingBase] = useState(false);

	const [savedLarge, setSavedLarge] = useState(true);
	const [savingLarge, setSavingLarge] = useState(false);
	const [urlLarge, setUrlLarge] = useState('');
	const [saved2, setSaved2] = useState(true);
	const [saving2, setSaving2] = useState(false);
	const [url2, setUrl2] = useState('');
	const [saved4, setSaved4] = useState(true);
	const [saving4, setSaving4] = useState(false);
	const [url4, setUrl4] = useState('');

	const [fileBaseStats, setFileBaseStats] = useState(undefined);

	const [s3BaseImageURL, setS3BaseImageURL] = useState(undefined);

	// CROPPER

	const [crop1, setCrop1] = useState({ x: 0, y: 0 });
	const [crop2, setCrop2] = useState({ x: 0, y: 0 });
	const [crop4, setCrop4] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom1, setZoom1] = useState(1);
	const [zoom2, setZoom2] = useState(1);
	const [zoom4, setZoom4] = useState(2);

	const [cropped1Image, setCropped1Image] = useState(null);
	const [cropped1AreaPixels, setCropped1AreaPixels] = useState(null);
	const [cropped1PreviewUpdated, setCropped1PreviewUpdated] = useState(false);

	const [cropped2Image, setCropped2Image] = useState(null);
	const [cropped2AreaPixels, setCropped2AreaPixels] = useState(null);
	const [cropped2PreviewUpdated, setCropped2PreviewUpdated] = useState(false);

	const [cropped4Image, setCropped4Image] = useState(null);
	const [cropped4AreaPixels, setCropped4AreaPixels] = useState(null);
	const [cropped4PreviewUpdated, setCropped4PreviewUpdated] = useState(false);

	const onCrop1Complete = useCallback((croppedArea, croppedAreaPixels) => {
		setCropped1AreaPixels(croppedAreaPixels);
		setCropped1PreviewUpdated(false);
	}, []);

	const onCrop2Complete = useCallback((croppedArea, croppedAreaPixels) => {
		setCropped2AreaPixels(croppedAreaPixels);
		setCropped2PreviewUpdated(false);
	}, []);

	const onCrop4Complete = useCallback((croppedArea, croppedAreaPixels) => {
		setCropped4AreaPixels(croppedAreaPixels);
		setCropped4PreviewUpdated(false);
	}, []);

	const showCropped1Image = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(firebaseImageURL, cropped1AreaPixels, rotation);
			console.log('donee', { croppedImage });
			setCropped1Image(croppedImage);
			setCropped1PreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
	}, [cropped1AreaPixels]);

	const showCropped2Image = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(firebaseImageURL, cropped2AreaPixels, rotation);
			console.log('donee', { croppedImage });
			setCropped2Image(croppedImage);
			setCropped2PreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
	}, [cropped2AreaPixels]);

	const showCropped4Image = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(firebaseImageURL, cropped4AreaPixels, rotation);
			console.log('donee', { croppedImage });
			setCropped4Image(croppedImage);
			setCropped4PreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
	}, [cropped4AreaPixels]);

	const ref = app.firestore().collection('items').doc(itemid);

	const getBaseImage = async (props) => {
		if (props.images && props.images.fb_base) {
			setBaseImageURL(props.images.fb_base);
			setFirebaseImageURL(props.images.fb_base);
			if (props.images.s3_base) {
				setS3BaseImageURL(props.images.s3_base);
			}
		}
	};

	function getitem() {
		const init = async () => {
			setLoading(true);
			setLoadingImage(true);
			const doc = await ref.get();
			const itemData = await doc.data();

			getBaseImage(itemData);

			setItem(itemData);
			setLoading(false);
			setLoadingImage(false);
		};

		init();
	}

	useEffect(() => {
		getitem();
	}, []);

	if (loading || item.name === undefined) {
		return (
			<>
				<SiteNav user={currentUser.email} tag="Editing" showPages={true} />
				<LoadingCard />
			</>
		);
	}

	const readFile = async (file) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			let img = new Image();
			img.onload = () => {
				setFileBaseStats({ width: img.width, height: img.height });
			};
			img.src = reader.result;
		};

		reader.readAsDataURL(file);
	};

	const onFileChange = async (e) => {
		setLoadingImage(true);
		const file = e.target.files[0];
		readFile(file);

		const storageRef = app.storage().ref();
		const fileRef = storageRef.child(`images/equipment/${itemid}/base/${itemid}_base.png`);
		await fileRef.put(file);
		const imageURL = await fileRef.getDownloadURL();
		updateStore(itemid, { fb_base: imageURL });
		setFirebaseImageURL(imageURL);
		setBaseImageURL(imageURL);
		setLoadingImage(false);
		setSavedBase(false);
	};

	const handleSaveBase = async () => {
		if (firebaseImageURL) {
			setSavingBase(true);
			const s3URL = await compressToS3(firebaseImageURL, `${item.id}_equipment_base.png`);
			await updateStore(itemid, { s3_base: s3URL });
			console.log(s3URL);
			setS3BaseImageURL(s3URL);
			setSavingBase(false);
			setSavedBase(true);
		}
	};

	const resetURL = () => {
		setUrlLarge('');
		setUrl2('');
		setUrl4('');
	};

	const handleUploadCropped1Image = async () => {
		resetURL();
		try {
			setSavingLarge(true);
			const imageURL = await uploadCroppedImg(firebaseImageURL, cropped1AreaPixels, rotation);
			console.log('donee', { imageURL });

			// TO AWS
			const s3Url = await resizeCompressToS3(imageURL, `${item.id}_equipment_large.png`, 1400, 1600);
			setUrlLarge(s3Url);

			setSavingLarge(false);
			setSavedLarge(false);
		} catch (e) {
			console.error(e);
		}
	};

	const handleUploadCropped2Image = async () => {
		resetURL();
		try {
			setSaving2(true);
			const imageURL = await uploadCroppedImg(firebaseImageURL, cropped2AreaPixels, rotation);
			console.log('donee', { imageURL });

			// TO AWS
			const s3Url = await resizeCompressToS3(imageURL, `${item.id}_equipment_preview.png`, 256, 288);
			setUrl2(s3Url);

			setSaving2(false);
			setSaved2(false);
		} catch (e) {
			console.error(e);
		}
	};

	const handleUploadCropped4Image = async () => {
		resetURL();
		try {
			setSaving4(true);
			const imageURL = await uploadCroppedImg(firebaseImageURL, cropped4AreaPixels, rotation);
			console.log('donee', { imageURL });

			// TO AWS
			const s3Url = await resizeCompressToS3(imageURL, `${item.id}_equipment_thumbnail.png`, 74, 74);
			setUrl4(s3Url);

			setSaving4(false);
			setSaved4(false);
		} catch (e) {
			console.error(e);
		}
	};

	const compressToS3 = async (url, fileName) => {
		const compress_images = app.functions().httpsCallable('compress_images');
		try {
			const { data: s3URL } = await compress_images({ url, fileName });
			return s3URL;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	const resizeCompressToS3 = async (url, fileName, width, height) => {
		const resize_and_compress = app.functions().httpsCallable('resize_and_compress');
		try {
			const { data: s3URL } = await resize_and_compress({ url, fileName, width, height });
			return s3URL;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	return (
		<div style={{ backgroundColor: 'WhiteSmoke' }} className="App">
			<SiteNav user={currentUser.email} tag={`Reviewing ${item.name}`} showPages={true} />
			<div className="m-2">
				<h2>
					{item.id}. {item.name}
				</h2>

				<h3>Base Image</h3>
				<p>Specs: full size at least 2000 x 2000px / min 300 dpi / uncropped / PNG with alpha (you should see light tan bg in the preview)</p>
				<Row>
					<Col>
						{loadingImage && <Spinner className="text-center image-coin-review" animation="border" variant="primary" />}
						{!loadingImage && <img style={{ background: 'AntiqueWhite' }} className="image-coin-review" src={baseImageURL} alt="base" />}
					</Col>
					<Col>
						{fileBaseStats && (
							<p>
								Width: {fileBaseStats.width}
								<br></br> Height: {fileBaseStats.height}
							</p>
						)}
					</Col>
				</Row>

				<Form.Group>
					<Row>
						<Col>
							<input style={{ background: 'AliceBlue' }} className="m-2" type="file" onChange={(e) => onFileChange(e)} />
						</Col>
					</Row>
					<Row>
						<Col>
							<Button className="m-2" onClick={handleSaveBase} variant={`${savedBase ? 'primary' : 'warning'}`} type="submit">
								{savingBase ? 'Saving...' : savedBase ? 'Choose a file' : 'Compress and Approve for AWS'}
								{savingBase && <Spinner className="text-center" size="sm" animation="border" variant="light" />}
							</Button>
							<p className="mx-2">
								{!s3BaseImageURL && 'No copy found on AWS'}
								{s3BaseImageURL && `Image is saved on AWS as ${s3BaseImageURL}`}
							</p>
						</Col>
					</Row>
				</Form.Group>

				<div style={{ height: '6px', width: '100%', backgroundColor: 'purple' }}></div>
				{/* CROPPER1 */}
				<div className="crop-wrapper">
					<h3>Large Image</h3>
					<p>Character will go behind the text</p>
					<Button variant={cropped1PreviewUpdated ? 'primary' : 'warning'} className="m-2" onClick={showCropped1Image}>
						{cropped1PreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
					</Button>

					<Button variant={cropped1PreviewUpdated ? 'success' : 'warning'} className="m-2" onClick={handleUploadCropped1Image}>
						{cropped1PreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
					</Button>
					<span className="mx-2 font-weight-bold text-success">{savingLarge ? 'Saving...' : savedLarge ? '' : `DONE! ${urlLarge}`}</span>
					{savingLarge && <Spinner className="" animation="border" variant="primary" />}
					<div className="mx-2 mt-2 max-width" style={{ width: '256px' }}>
						<span>
							<strong>ZOOM SLIDER:</strong>
						</span>
						<RangeSlider variant="secondary" min={0.2} max={5} step={0.02} tooltipPlacement="top" value={zoom1} onChange={(changeEvent) => setZoom1(changeEvent.target.value)} />
					</div>
					<Row>
						<div className="crop-container">
							<Cropper
								image={firebaseImageURL}
								minZoom={0.2}
								maxZoom={5}
								zoomSpeed={0.02}
								restrictPosition={false}
								crop={crop1}
								zoom={zoom1}
								aspect={7 / 8}
								onCropChange={setCrop1}
								onCropComplete={onCrop1Complete}
								onZoomChange={setZoom1}
								objectFit="contain"
							/>
						</div>
						<div className="crop-preview-1">
							<img className="crop-preview-1" src={cropped1Image} alt="base" />
						</div>
					</Row>

					<div style={{ height: '6px', width: '100%', backgroundColor: 'purple' }}></div>

					<h3 className="pt-5">Preview Card Image</h3>
					<p>On the page with all the Ethemerals. Character will go behind the text.</p>

					<Button variant={cropped2PreviewUpdated ? 'primary' : 'warning'} className="m-2" onClick={showCropped2Image}>
						{cropped2PreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
					</Button>

					<Button variant={cropped2PreviewUpdated ? 'success' : 'warning'} className="m-2" onClick={handleUploadCropped2Image}>
						{cropped2PreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
					</Button>
					<span className="mx-2 font-weight-bold text-success">{saving2 ? 'Saving...' : saved2 ? '' : `DONE! ${url2}`}</span>
					{saving2 && <Spinner className="" animation="border" variant="primary" />}
					<div className="mx-2 mt-2 max-width" style={{ width: '200px' }}>
						<span>
							<strong>ZOOM SLIDER:</strong>
						</span>
						<RangeSlider variant="secondary" min={0.2} max={5} step={0.05} tooltipPlacement="top" value={zoom2} onChange={(changeEvent) => setZoom2(changeEvent.target.value)} />
					</div>
					<Row>
						<div className="crop-container2">
							<Cropper
								image={firebaseImageURL}
								minZoom={0.2}
								maxZoom={5}
								zoomSpeed={0.05}
								restrictPosition={false}
								crop={crop2}
								zoom={zoom2}
								aspect={8 / 9}
								onCropChange={setCrop2}
								onCropComplete={onCrop2Complete}
								onZoomChange={setZoom2}
								objectFit="contain"
								zoomWithScroll={false}
							/>
						</div>
						<div style={{ position: 'relative', left: 0, top: 0, background: 'DarkSlateGrey', width: '256px', height: '288px' }}>
							<img style={{ position: 'relative', top: 0, left: 0, width: '256px', height: '288px' }} src={cropped2Image} alt="base" />
							<img style={{ position: 'absolute', top: 0, left: 0 }} className="crop-preview-2" src="https://ethemerals-media.s3.amazonaws.com/firebase_card_preview.png" alt="base" />
						</div>
					</Row>
					<div style={{ height: '6px', width: '100%', backgroundColor: 'purple' }}></div>

					<h3 className="pt-5">Thumbnail Image</h3>
					<p>small portrait on the Inventory account tab and battle current fighters</p>
					<Button variant={cropped4PreviewUpdated ? 'primary' : 'warning'} className="m-2" onClick={showCropped4Image}>
						{cropped4PreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
					</Button>

					<Button variant={cropped4PreviewUpdated ? 'success' : 'warning'} className="m-2" onClick={handleUploadCropped4Image}>
						{cropped4PreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
					</Button>
					<span className="mx-2 font-weight-bold text-success">{saving4 ? 'Saving...' : saved4 ? '' : `DONE! ${url4}`}</span>
					{saving4 && <Spinner className="" animation="border" variant="primary" />}
					<div className="mx-2 mt-2 max-width" style={{ width: '200px' }}>
						<span>
							<strong>ZOOM SLIDER:</strong>
						</span>
						<RangeSlider variant="secondary" min={0.1} max={6} step={0.05} tooltipPlacement="top" value={zoom4} onChange={(changeEvent) => setZoom4(changeEvent.target.value)} />
					</div>

					<Row>
						<div className="crop-container4">
							<Cropper
								image={firebaseImageURL}
								minZoom={0.1}
								maxZoom={6}
								zoomSpeed={0.1}
								restrictPosition={false}
								crop={crop4}
								zoom={zoom4}
								aspect={1 / 1}
								onCropChange={setCrop4}
								onCropComplete={onCrop4Complete}
								onZoomChange={setZoom4}
								objectFit="contain"
								zoomWithScroll={false}
							/>
						</div>
						<div style={{ position: 'relative', top: 0, left: 0, backgroundColor: 'DarkSlateGrey', width: '74px', height: '74px' }}>
							<img style={{ position: 'relative', top: 0, left: 0, backgroundColor: 'DarkSlateGrey', width: '74px', height: '74px' }} className="crop-preview-4" src={cropped4Image} alt="base" />
						</div>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default EquipmentReview;
