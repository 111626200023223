import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import CoinReview from './Components/CoinReview';
import CoinEdit from './Components/CoinEdit';
import Stats from './Stats';
import Images from './Images';
import Equipment from './Equipment';
import S3Bucket from './S3Bucket';
import EquipmentReview from './Components/EquipmentReview';
import { AuthProvider } from './Auth';
import PrivateRoute from './PrivateRoute';

import './App.css';

function App() {
	return (
		<AuthProvider>
			<Router>
				<PrivateRoute exact path="/" component={Home} />
				<Route exact path="/login" component={Login} />
				<PrivateRoute path="/page/:pagenumber" component={Home} />
				<PrivateRoute path="/stats/:pagenumber" component={Stats} />
				<PrivateRoute path="/images/:pagenumber" component={Images} />
				<PrivateRoute path="/equipment/:equiptype" component={Equipment} />
				<PrivateRoute path="/edit/:coinid" component={CoinEdit} />
				<PrivateRoute path="/review/:coinid" component={CoinReview} />
				<PrivateRoute path="/equipmentreview/:itemid" component={EquipmentReview} />
				<PrivateRoute path="/s3bucket" component={S3Bucket} />
			</Router>
		</AuthProvider>
	);
}

export default App;
