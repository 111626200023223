import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import app from './firebase';

import { AuthContext } from './Auth';

import SiteNav from './Components/SiteNav';
import CoinImages from './Components/CoinImages';
import LoadingCard from './Components/LoadingCard';

import useStatsUtils from './hooks/useStatsUtils';

import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Images() {
	let { pagenumber } = useParams();
	if (!pagenumber) pagenumber = 1;

	const { currentUser } = useContext(AuthContext);
	const [coins, setCoins] = useState([]);
	const [loading, setLoading] = useState(false);

	const ref = app.firestore().collection('coins');

	function getCoins() {
		async function init() {
			const coinsPerPage = 80;
			const coinsStartAt = pagenumber === 1 ? 1 : coinsPerPage * pagenumber - coinsPerPage;

			setLoading(true);
			const _coins = [];
			const snapshot = await ref.orderBy('rank').startAt(coinsStartAt).limit(coinsPerPage).get();
			snapshot.forEach((doc) => {
				const coinObject = doc.data();
				_coins.push(coinObject);
			});

			setCoins(_coins);
			setLoading(false);
		}

		init();
	}

	useEffect(() => {
		getCoins();
	}, []);

	// RENDER

	const NavigationBar = <SiteNav user={currentUser.email} tag={`Coin Images Page ${pagenumber}`} showPages={true} />;
	if (loading) {
		return (
			<>
				{NavigationBar}
				<LoadingCard />
			</>
		);
	}

	return (
		<div className="App">
			{NavigationBar}

			<CardGroup>
				{coins.map((coin, index) => {
					return (
						<div key={index}>
							<CoinImages props={coin} />
						</div>
					);
				})}
			</CardGroup>
		</div>
	);
}

export default Images;
