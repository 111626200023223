import React from 'react';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

const LoadingCard = () => {

    return (
        <>
            <div className="card-spacer"></div>
            <div className="card-spacer"></div>
            <Container>
                <Card className="text-center">
                    <div className="card-spacer"></div>
                    <h3>Loading <Spinner className="text-center" animation="border" variant="primary" /></h3>
                    <div className="card-spacer"></div>
                </Card>
            </Container>
        </>
    )
}

export default LoadingCard;