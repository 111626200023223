import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const SavingCard = (props) => {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Body className="text-center">
                <div className="card-spacer"></div>
                <h4>Saving To Databaes</h4>
                <div className="card-spacer"></div>
                <h3><Spinner className="text-center" animation="grow" variant="info" /></h3>
                <div className="card-spacer"></div>
            </Modal.Body>
        </Modal>
      );
}

export default SavingCard;