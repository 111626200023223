import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import app from '../firebase';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const EquipmentImages = ({ props }) => {
	const history = useHistory();

	const [baseImageURL, setBaseImageURL] = useState(undefined);

	const [stats, setStats] = useState({
		class: 'CLASS',
		subclass: 'SUBCLASS',
		special1: 'NONE',
		special2: 'NONE',
	});

	const getBaseImage = async (props) => {
		if (props.images) {
			if (props.images.fb_base) {
				setBaseImageURL(props.images.fb_base);
			}
		} else {
			setBaseImageURL(props.nft[props.nft.length - 1].images.ss);
		}
	};

	useEffect(() => {
		if (props) {
			getBaseImage(props);
		}
		if (props.stats) {
			setStats(props.stats);
		}
	}, []);

	if (!props) {
		return <div>Loading</div>;
	}

	const classType = () => {
		let className = 'coin-stats-top';
		switch (stats.class) {
			case 'warrior':
				return (className += ' stats-warrior');

			case 'mage':
				return (className += ' stats-mage');

			case 'rogue':
				return (className += ' stats-rogue');

			default:
				return className;
		}
	};

	return (
		<Card className={`coin-images-card`}>
			<Card.Body className={classType()}>
				<Row>
					<Col className="text-left">
						{props.id}. {props.name}
					</Col>
				</Row>
				<Row>{baseImageURL && <img className="image-coin-thumbnail" src={baseImageURL} alt="base" />}</Row>
				<Row>
					<Button onClick={() => history.push(`/equipmentreview/${props.id}`)} variant="secondary">
						Edit
					</Button>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default EquipmentImages;
