// prettier-ignore
export const mainclass = [
  'warrior',
  'mage',
  'rogue'
]

// prettier-ignore
export const subclass = {
  warrior: [
    'paladin',
    'knight',
    'dark-knight',
    'dragoon'
  ],
  mage: [
    'sorcerer',
    'summoner',
    'cleric',
    'druid'
  ],
  rogue: [
    'ranger',
    'berserker',
    'assassin',
    'monk'
  ]
}

// prettier-ignore
export const hairColors = [
  'brown',
  'black',
  'blonde',
  'silver',
  'white',
  'orange',
  'peach',
  'red',
  'blue',
  'pastel blue',
  'purple',
  'pastel purple',
  'pink',
  'green',
  'pastel green',
  'streaks',
  'balayage',
  'mixed',
]

// prettier-ignore
export const eyeColors = [
  'brown',
  'black',
  'yellow',
  'silver',
  'white',
  'orange',
  'peach',
  'red',
  'blue',
  'pastel blue',
  'purple',
  'pastel purple',
  'pink',
  'green',
  'pastel green',
  'ishokushou'
]

// prettier-ignore
export const skinColors = [
  'pale',
  'tanned',
  'brown',
  'dark',
  'metalic',
  'plastic',
  'elemental'
]

// prettier-ignore
export const costume = [
  'foodie',
  'uniform',
  'sporty',
  'tribal',
  'cyborg',
  'elemental',
  'military',
  'modern',
  'casual',
  'traditional',
  'futuristic',
  'medieval',
  'formal',
  'performance',
  'dancer',
  'trendy',
  'mystic',
  'furry',
  'mascot',
  'chef',
  'android',
  'astronaut'
]

// prettier-ignore
export const specials = {
  warrior: [
    'braver',
    'triple-slash',
    'disorder',
    "infinity's-end",
    'blade-burst',
    'counterstance',
    'steelskin',
    'lifesaver',
    'unbridled-strength',
    'overpower'
  ],
  mage: [
    'starshower',
    'stardust-ray',
    'soul-drain',
    'arcane-ward',
    'sorcerous-storm',
    'fleeting-familiar',
    'ray-of-judgement',
    'lustrous-shield',
    'invisibility',
    'resurrection'
  ],
  rogue: [
    'focused-thrust',
    'focused-strike',
    'focused-shot',
    'true-strike',
    'maximum-fury',
    'charging-uppercut',
    'smackdown',
    'point-blank',
    'divekick',
    'chi-trap'
  ],
};

// prettier-ignore
export const specialDesc = {
  warrior: [
    'Jump into the air and strike your foe with a powerful attack.',
    'Slash three enemies in quick succession',
    'Deliver a devastating attack and switch modes in one motion',
    'Wind up and unleash an overhead strike',
    'Unleash a wave of non-elemental mako energy',
    'Brace for attacks and retaliate with a powerful slash',
    'Temporarily reduce damage taken and how easily you are interrupted',
    'Take damage intended for other party members',
    'Strengthen basic attacks and unique abilities',
    'Use in conjunction with basic attacks to more effectively pressure enemies'
  ],
  mage: [
    'Unleash a flurry of powerful strikes. Increases magic',
    'Bombards enemies with stars',
    'Unleash an attack that absorbs MP',
    'Conjure a ward',
    'Deal magic damage to nearby enemies',
    'Summon a fairy that attacks your enemies periodically as wells',
    'Fire an energy burst that hits multiple times',
    'Conjure a magical shield that keeps enemies at bay',
    'dissappear',
    'Revive oneself or others'
  ],
  rogue: [
    'Lunge toward an enemy with a piercing strike that hits multiple times',
    'Evade, then unleash a charging attack. Moderately increases stagger',
    'Consume all ATB charges to unleash a concentrated burst of energy',
    'Deliver a tremendous blow at close range',
    'Consume all ATB charges to fire a long stream of projectiles at an enemy',
    'Rush towards an enemy and launch them into the air with a furious blow',
    'Strike the ground and send nearby enemies flying',
    'Consume all ATB charges to deliver a close-range attack',
    'Leap into the air and unleash a powerful kick',
    'Create a ball of energy that does continuous damage'
  ]

}
