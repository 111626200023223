import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../Auth';

import app from '../firebase';
import SiteNav from './SiteNav';
import LoadingCard from './LoadingCard';
import SavingCard from './SavingCard';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function CoinEdit() {
	const { coinid } = useParams();
	const { currentUser } = useContext(AuthContext);

	const [coin, setCoin] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [imageURLs, setImageURLs] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [coinNFTArray, setCoinNFTArray] = useState([]);

	const ref = app.firestore().collection('coins').doc(coinid);

	function getCoin() {
		const init = async () => {
			setLoading(true);
			const doc = await ref.get();
			const coinData = await doc.data();

			setCoin(coinData);
			setCoinNFTArray(coinData.nft);
			setLoading(false);
		};

		init();
	}

	useEffect(() => {
		getCoin();
	}, []);

	// IMAGES
	const setImagePath = (rank) => {
		return <img className="image-coin-thumbnail" src={imageURLs[rank]} alt={rank} />;
	};

	const ImageSS = () => {
		if (imageURLs.ss) {
			return setImagePath('ss');
		} else {
			return <div></div>;
		}
	};

	useEffect(() => {
		ImageSS();
	}, [imageURLs]);

	// FORM
	const { handleSubmit, register, watch } = useForm({});

	function toTimeStamp(strDate) {
		const datum = Date.parse(strDate);
		return datum;
	}

	const updateStore = async (doc) => {
		try {
			const docRef = app.firestore().collection('coins').doc(coinid.toString());
			coinNFTArray.push(doc);
			coin.nft = coinNFTArray;

			await docRef.update({
				nft: coinNFTArray,
			});

			setModalShow(false);
			setCoin(coin);
			setCoinNFTArray(coinNFTArray);
			console.log(coin);
		} catch (e) {
			console.log(e);
		}
	};

	async function onSubmit(formData) {
		setModalShow(true);
		const timestamp = toTimeStamp(formData.birthday);
		const tsNow = Date.now();
		const properties = formData.properties.split('\n');
		formData.birthday = timestamp;
		formData.properties = properties;
		formData.images = imageURLs;
		formData.timestamp = tsNow;
		// console.log(formData);
		updateStore(formData);
	}

	if (loading) {
		return (
			<>
				<SiteNav user={currentUser.email} tag="Editing" showPages={true} />
				<LoadingCard />
			</>
		);
	}

	if (coin.name) {
		const coinLatestNFT = coin.nft[coin.nft.length - 1];
		register.defaultValues = coin.nft[coin.nft.length - 1];
		const NFTName = watch('name');
		const NFTArtist = watch('artist');
		const NFTProperties = watch('properties');
		const NFTReferenceLinks = watch('reference_links');
		const NFTDesignHints = watch('design_hints');

		if (!imageURLs.ss) {
			imageURLs.ss = coinLatestNFT.images.ss;
		}

		const onFileChange = async (e) => {
			const rank = e.target.id;
			const file = e.target.files[0];
			const storageRef = app.storage().ref();
			// const s3URL = await handleSaveImage(file);
			// console.log(s3URL);
			const timestamp = Date.now();
			const fileRef = storageRef.child(`images/baes/${coinid}/${timestamp.toString()}/${rank}/${file.name}`);
			await fileRef.put(file);
			const imageURL = await fileRef.getDownloadURL();
			const _imageURLs = {
				...imageURLs,
			};
			_imageURLs[rank] = imageURL;
			setImageURLs(_imageURLs);
		};

		const handleSaveImage = async (file) => {
			const nft_save_images = app.functions().httpsCallable('nft_save_images');
			const { data: s3URL } = await nft_save_images();

			// let result = await makeRequest('GET', imageURLs['ss']);
			// console.log(result);

			await fetch(s3URL, {
				method: 'PUT',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				body: file,
			});

			const s3ImageUrl = s3URL.split('?')[0];
			return s3ImageUrl;
		};

		const link = `https://coinmarketcap.com/currencies/${coin.name.replace(/\s+/g, '-')}/`;

		let coinTags = <div></div>;
		const NFTProps = [];

		if (coin.tags) {
			coinTags = (
				<Card.Body className="text-right card-top">
					{coin.tags.map((tag, index) => (
						<Badge key={`${coin.id}${index}`} variant="warning" pill>
							<small>{tag}</small>
						</Badge>
					))}
				</Card.Body>
			);
		}

		const NFTPropertiesToRender = (hasProps) => {
			if (hasProps) {
				return (
					<Card.Body className="text-left zero-left-padding">
						{NFTProps.map((tag, index) => {
							return (
								<div key={index}>
									<div className="coin-properties" variant="info">
										{tag}
									</div>
								</div>
							);
						})}
					</Card.Body>
				);
			} else {
				return <div></div>;
			}
		};

		const NFTPropertiesUpdate = () => {
			const propArray = NFTProperties.split('\n');
			return (
				<Card.Body className="text-left zero-left-padding">
					{propArray.map((tag, index) => {
						return (
							<div key={index}>
								<div className="coin-properties" variant="info">
									{tag}
								</div>
							</div>
						);
					})}
				</Card.Body>
			);
		};

		const parseNFTProps = () => {
			const props = NFTProps.join('\n');
			return props;
		};

		if (coinLatestNFT.properties) {
			coinLatestNFT.properties.forEach((tag) => {
				NFTProps.push(tag);
			});

			NFTPropertiesToRender(true);
		}

		return (
			<div className="App">
				<SiteNav user={currentUser.email} tag={`Editing ${coin.name}`} showPages={true} />
				<div className="card-spacer"></div>
				<Container>
					<Card className="coin-full-card">
						<Card.Body className="text-right card-top coin-short">
							<Row>
								<Col className="text-left">
									{coin.rank}. {coin.symbol}
								</Col>
								<Col className="text-left">
									<img src={coin.logo} alt="logo" width="24" height="24" />
								</Col>
								<Col className="text-left">{coin.name}</Col>
								<Col>
									<Button variant="light" size="sm">
										<a href={link} target="_blank" rel="noreferrer">
											<small>external link</small>
										</a>
									</Button>
								</Col>
								<Col>
									<small className="text-muted">Category: {coin.category}</small>
								</Col>
							</Row>
						</Card.Body>
						{coinTags}

						<Card.Body className="coin-nft-heading">
							<Row>
								<Col className="text-left">{NFTName ? NFTName : coinLatestNFT.name}</Col>
							</Row>
						</Card.Body>
						<Card.Body className="coin-short coin-body">
							<Row>
								<Col className="coin-preview text-right" xs>
									<ImageSS />
									Artist: {NFTArtist ? NFTArtist : coinLatestNFT.artist}
								</Col>
								<Col className="text-left">{NFTProperties ? <NFTPropertiesUpdate /> : <NFTPropertiesToRender />}</Col>
							</Row>
						</Card.Body>
						<Card.Body className="coin-short card-top card-design-hints">
							<small>Ref Links: </small>{' '}
							{NFTReferenceLinks ? (
								<a href={NFTReferenceLinks} target="_blank" rel="noreferrer">
									<small>external reference link</small>
								</a>
							) : coinLatestNFT.reference_links.length > 0 ? (
								<a href={coinLatestNFT.reference_links} target="_blank" rel="noreferrer">
									<small>external reference link</small>
								</a>
							) : (
								<div></div>
							)}
						</Card.Body>
						<Card.Body className="coin-short card-top card-design-hints">
							<small>Design Notes: </small> {NFTDesignHints ? NFTDesignHints : coinLatestNFT.design_hints}
						</Card.Body>
					</Card>
					<p></p>
					<Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
						<Col className="text-right">
							<Button variant="primary" type="submit">
								Save to databaes
							</Button>
						</Col>
						<Form.Row>
							<Form.Group as={Col} controlId="formGridEmail">
								<Form.Label className="Modal-form-label">Bae Name:</Form.Label>
								<Form.Control name="name" defaultValue={register.defaultValues.name} ref={register} />
							</Form.Group>
						</Form.Row>

						<Form.Group controlId="formGridAddress1">
							<Form.Label className="Modal-form-label">Artist:</Form.Label>
							<Form.Control defaultValue={register.defaultValues.artist} name="artist" ref={register} />
						</Form.Group>
						<Form.Group>
							<Form.Label className="Modal-form-label">
								Properties: <small>No Spaces, use - for spaces</small>
							</Form.Label>
							<Form.Control as="textarea" defaultValue={parseNFTProps()} name="properties" ref={register} rows={6} />
						</Form.Group>
						<Form.Group controlId="formGridAddress2">
							<Form.Label className="Modal-form-label">Reference Links:</Form.Label>
							<Form.Control defaultValue={register.defaultValues.reference_links} name="reference_links" ref={register} />
						</Form.Group>
						<Form.Group controlId="exampleForm.ControlTextarea1">
							<Form.Label className="Modal-form-label">Design Notes:</Form.Label>
							<Form.Control as="textarea" name="design_hints" defaultValue={register.defaultValues.design_hints} rows={3} ref={register} />
						</Form.Group>
						<Col className="text-right">
							<Button variant="primary" type="submit">
								Save to databaes
							</Button>
						</Col>
						<Form.Group>
							<Form.Label className="Modal-form-label">Base Image</Form.Label>
							<Row>
								<Col>
									<input className="form-control-file" type="file" id="ss" onChange={(e) => onFileChange(e)} />
								</Col>
								<Col>
									<ImageSS />
								</Col>
							</Row>
						</Form.Group>
						<Col className="text-right">
							<Button variant="primary" type="submit">
								Save to databaes
							</Button>
						</Col>
					</Form>
				</Container>
				<div className="card-spacer"></div>
				<div className="card-spacer"></div>
				<div className="card-spacer"></div>
				<SavingCard show={modalShow} animation={false} onHide={() => setModalShow(false)} />
			</div>
		);
	}
}

export default CoinEdit;
