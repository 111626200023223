import React, { useEffect, useState } from 'react';
import app from '../firebase';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Spinner from 'react-bootstrap/Spinner';

const updateStore = async (coinId, data) => {
	try {
		const docRef = app.firestore().collection('coins').doc(coinId.toString());
		const doc = await docRef.get();
		const docData = await doc.data();

		let images = docData.images;
		images = {
			...images,
			...data,
		};

		await docRef.update({
			images,
		});
	} catch (e) {
		console.log(e);
	}
};

const UploadImagePreview = ({ coinid, coinData, setBaseImageParent, colorType }) => {
	const [coin, setCoin] = useState(undefined);
	const [loadingImage, setLoadingImage] = useState(true);
	const [imageURL, setImageURL] = useState(undefined);

	const [saved, setSaved] = useState(true);
	const [saving, setSaving] = useState(false);

	const [s3ImageURL, setS3ImageURL] = useState(undefined);

	const getImageBase = async (props) => {
		if (props.images && props.images.fb_base) {
			setBaseImageParent(props.images.fb_base);
			setImageURL(props.images.fb_base);
		} else {
			setImageURL(props.nft[props.nft.length - 1].images.ss);
			setBaseImageParent(props.images.fb_base);
		}
		if (props.images && props.images.s3_base) {
			setS3ImageURL(props.images.s3_base);
		}
	};

	const getImage = async (props) => {
		if (props.images) {
			setImageURL(props.images[`fb_${colorType}`]);
			setS3ImageURL(props.images[`s3_${colorType}`]);
		}
	};

	useEffect(() => {
		if (colorType === 'base') {
			getImageBase(coinData);
		} else {
			getImage(coinData);
		}
		setCoin(coinData);
		setLoadingImage(false);
	}, [coinid, coinData, colorType]);

	const onFileChange = async (e) => {
		setLoadingImage(true);
		const file = e.target.files[0];

		const storageRef = app.storage().ref();
		const fileRef = storageRef.child(`images/baes/${coinid}/base/${coinid}_${colorType}.png`);
		await fileRef.put(file);
		const imageURL = await fileRef.getDownloadURL();

		let imgObj = {};
		imgObj[`fb_${colorType}`] = imageURL;

		updateStore(coinid, imgObj);
		setImageURL(imageURL);
		setBaseImageParent(imageURL);
		setLoadingImage(false);
		setSaved(false);
	};

	const handleSave = async () => {
		if (imageURL) {
			setSaving(true);
			const s3URL = await compressToS3(imageURL, `${coin.id}_${colorType}.png`);

			let imgObj = {};
			imgObj[`s3_${colorType}`] = s3URL;

			await updateStore(coinid, imgObj);
			console.log(s3URL);
			setS3ImageURL(s3URL);
			setSaving(false);
			setSaved(true);
		}
	};

	const compressToS3 = async (url, fileName) => {
		const compress_images = app.functions().httpsCallable('compress_images');
		try {
			const { data: s3URL } = await compress_images({ url, fileName });
			return s3URL;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	return (
		<div className="mr-4" style={{ width: '256px', backgroundColor: 'silver' }}>
			<Row>
				<Col>
					{loadingImage && <Spinner className="text-center image-coin-review" animation="border" variant="primary" />}
					{!loadingImage && <img style={{ background: 'AntiqueWhite' }} className="image-coin-review" src={imageURL} alt={`${colorType}`} />}
				</Col>
			</Row>

			<Form.Group>
				<Row>
					<Col>
						<h4>{colorType}</h4>
						<input style={{ background: 'AliceBlue' }} className="m-2" type="file" onChange={(e) => onFileChange(e)} />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button className="m-2" onClick={handleSave} variant={`${saved ? 'primary' : 'warning'}`} type="submit">
							{saving ? 'Saving...' : saved ? 'Choose a file' : 'Compress and Approve for AWS'}
							{saving && <Spinner className="text-center" size="sm" animation="border" variant="light" />}
						</Button>
						<p className="mx-2">
							<small>
								{!s3ImageURL && 'No copy found on AWS'}
								{s3ImageURL && `Saved on AWS as ${s3ImageURL}`}
							</small>
						</p>
					</Col>
				</Row>
			</Form.Group>
		</div>
	);
};

export default UploadImagePreview;
