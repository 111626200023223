import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../Auth';

import Cropper from 'react-easy-crop';
import RangeSlider from 'react-bootstrap-range-slider';
import getCroppedImg, { uploadCroppedImg } from './CroppedImage';

import app from '../firebase';
import SiteNav from './SiteNav';
import LoadingCard from './LoadingCard';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import UploadImagePreview from './UploadImagePreview';

const CoinReview = () => {
	const { coinid } = useParams();
	const { currentUser } = useContext(AuthContext);

	const [coin, setCoin] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [firebaseImageURL, setFirebaseImageURL] = useState(undefined);

	const [savedLarge, setSavedLarge] = useState(true);
	const [savingLarge, setSavingLarge] = useState(false);
	const [urlLarge, setUrlLarge] = useState('');
	const [saved2, setSaved2] = useState(true);
	const [saving2, setSaving2] = useState(false);
	const [url2, setUrl2] = useState('');
	const [saved3, setSaved3] = useState(true);
	const [saving3, setSaving3] = useState(false);
	const [url3, setUrl3] = useState('');
	const [saved4, setSaved4] = useState(true);
	const [saving4, setSaving4] = useState(false);
	const [url4, setUrl4] = useState('');

	// CROPPER

	const [crop1, setCrop1] = useState({ x: 0, y: 0 });
	const [crop2, setCrop2] = useState({ x: 0, y: 0 });
	const [crop3, setCrop3] = useState({ x: 0, y: 0 });
	const [crop4, setCrop4] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom1, setZoom1] = useState(1);
	const [zoom2, setZoom2] = useState(1);
	const [zoom3, setZoom3] = useState(1);
	const [zoom4, setZoom4] = useState(2);

	const [cropped1Image, setCropped1Image] = useState(null);
	const [cropped1AreaPixels, setCropped1AreaPixels] = useState(null);
	const [cropped1PreviewUpdated, setCropped1PreviewUpdated] = useState(false);

	const [cropped2Image, setCropped2Image] = useState(null);
	const [cropped2AreaPixels, setCropped2AreaPixels] = useState(null);
	const [cropped2PreviewUpdated, setCropped2PreviewUpdated] = useState(false);

	const [cropped3Image, setCropped3Image] = useState(null);
	const [cropped3AreaPixels, setCropped3AreaPixels] = useState(null);
	const [cropped3PreviewUpdated, setCropped3PreviewUpdated] = useState(false);

	const [cropped4Image, setCropped4Image] = useState(null);
	const [cropped4AreaPixels, setCropped4AreaPixels] = useState(null);
	const [cropped4PreviewUpdated, setCropped4PreviewUpdated] = useState(false);

	const onCrop1Complete = useCallback((croppedArea, croppedAreaPixels) => {
		setCropped1AreaPixels(croppedAreaPixels);
		setCropped1PreviewUpdated(false);
	}, []);

	const onCrop2Complete = useCallback((croppedArea, croppedAreaPixels) => {
		setCropped2AreaPixels(croppedAreaPixels);
		setCropped2PreviewUpdated(false);
	}, []);

	const onCrop3Complete = useCallback((croppedArea, croppedAreaPixels) => {
		setCropped3AreaPixels(croppedAreaPixels);
		setCropped3PreviewUpdated(false);
	}, []);

	const onCrop4Complete = useCallback((croppedArea, croppedAreaPixels) => {
		setCropped4AreaPixels(croppedAreaPixels);
		setCropped4PreviewUpdated(false);
	}, []);

	const showCropped1Image = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(firebaseImageURL, cropped1AreaPixels, rotation);
			console.log('donee', { croppedImage });
			setCropped1Image(croppedImage);
			setCropped1PreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
	}, [cropped1AreaPixels]);

	const showCropped2Image = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(firebaseImageURL, cropped2AreaPixels, rotation);
			console.log('donee', { croppedImage });
			setCropped2Image(croppedImage);
			setCropped2PreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
	}, [cropped2AreaPixels]);

	const showCropped3Image = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(firebaseImageURL, cropped3AreaPixels, rotation);
			console.log('donee', { croppedImage });
			setCropped3Image(croppedImage);
			setCropped3PreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
	}, [cropped3AreaPixels]);

	const showCropped4Image = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(firebaseImageURL, cropped4AreaPixels, rotation);
			console.log('donee', { croppedImage });
			setCropped4Image(croppedImage);
			setCropped4PreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
	}, [cropped4AreaPixels]);

	const ref = app.firestore().collection('coins').doc(coinid);

	function getCoin() {
		const init = async () => {
			setLoading(true);
			const doc = await ref.get();
			const coinData = await doc.data();
			setCoin(coinData);
			setLoading(false);
		};

		init();
	}

	useEffect(() => {
		getCoin();
	}, []);

	if (loading || coin.name === undefined) {
		return (
			<>
				<SiteNav user={currentUser.email} tag="Editing" showPages={true} />
				<LoadingCard />
			</>
		);
	}

	const resetURL = () => {
		setUrlLarge('');
		setUrl2('');
		setUrl3('');
		setUrl4('');
	};

	const handleUploadCropped1Image = async () => {
		resetURL();
		let images = coin.images;
		try {
			setSavingLarge(true);
			if (images.fb_base) {
				const imageURL = await uploadCroppedImg(images.fb_base, cropped1AreaPixels, rotation);
				console.log('donee', { imageURL });
				// TO AWS
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_large.png`, 1400, 1600);
				setUrlLarge(s3Url);
			}
			if (images.fb_color2) {
				const imageURL = await uploadCroppedImg(images.fb_color2, cropped1AreaPixels, rotation);
				console.log('donee', { imageURL });
				// TO AWS
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_large_2.png`, 1400, 1600);
				setUrlLarge(s3Url);
			}
			if (images.fb_color3) {
				const imageURL = await uploadCroppedImg(images.fb_color3, cropped1AreaPixels, rotation);
				console.log('donee', { imageURL });
				// TO AWS
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_large_3.png`, 1400, 1600);
				setUrlLarge(s3Url);
			}
			if (images.fb_special) {
				const imageURL = await uploadCroppedImg(images.fb_special, cropped1AreaPixels, rotation);
				console.log('donee', { imageURL });
				// TO AWS
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_large_special.png`, 1400, 1600);
				setUrlLarge(s3Url);
			}

			setSavingLarge(false);
			setSavedLarge(false);
		} catch (e) {
			console.error(e);
		}
	};

	const handleUploadCropped2Image = async () => {
		resetURL();
		let images = coin.images;
		try {
			setSaving2(true);
			if (images.fb_base) {
				const imageURL = await uploadCroppedImg(images.fb_base, cropped2AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_preview.png`, 256, 384);
				setUrl2(s3Url);
			}
			if (images.fb_color2) {
				const imageURL = await uploadCroppedImg(images.fb_color2, cropped2AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_preview_2.png`, 256, 384);
				setUrl2(s3Url);
			}
			if (images.fb_color3) {
				const imageURL = await uploadCroppedImg(images.fb_color3, cropped2AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_preview_3.png`, 256, 384);
				setUrl2(s3Url);
			}
			if (images.fb_special) {
				const imageURL = await uploadCroppedImg(images.fb_special, cropped2AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_preview_special.png`, 256, 384);
				setUrl2(s3Url);
			}

			setSaving2(false);
			setSaved2(false);
		} catch (e) {
			console.error(e);
		}
	};

	const handleUploadCropped3Image = async () => {
		resetURL();
		let images = coin.images;
		try {
			setSaving3(true);
			if (images.fb_base) {
				const imageURL = await uploadCroppedImg(images.fb_base, cropped3AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_inventory.png`, 388, 112);
				setUrl3(s3Url);
			}
			if (images.fb_color2) {
				const imageURL = await uploadCroppedImg(images.fb_color2, cropped3AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_inventory_2.png`, 388, 112);
				setUrl3(s3Url);
			}
			if (images.fb_color3) {
				const imageURL = await uploadCroppedImg(images.fb_color3, cropped3AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_inventory_3.png`, 388, 112);
				setUrl3(s3Url);
			}
			if (images.fb_special) {
				const imageURL = await uploadCroppedImg(images.fb_special, cropped3AreaPixels, rotation);
				console.log('donee', { imageURL });
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_inventory_special.png`, 388, 112);
				setUrl3(s3Url);
			}

			setSaving3(false);
			setSaved3(false);
		} catch (e) {
			console.error(e);
		}
	};

	const handleUploadCropped4Image = async () => {
		resetURL();
		let images = coin.images;
		try {
			setSaving4(true);
			if (images.fb_base) {
				const imageURL = await uploadCroppedImg(images.fb_base, cropped4AreaPixels, rotation);
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_thumbnail.png`, 74, 74);
				setUrl4(s3Url);
			}
			if (images.fb_color2) {
				const imageURL = await uploadCroppedImg(images.fb_color2, cropped4AreaPixels, rotation);
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_thumbnail_2.png`, 74, 74);
				setUrl4(s3Url);
			}
			if (images.fb_color3) {
				const imageURL = await uploadCroppedImg(images.fb_color3, cropped4AreaPixels, rotation);
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_thumbnail_3.png`, 74, 74);
				setUrl4(s3Url);
			}
			if (images.fb_special) {
				const imageURL = await uploadCroppedImg(images.fb_special, cropped4AreaPixels, rotation);
				const s3Url = await resizeCompressToS3(imageURL, `${coin.id}_thumbnail_special.png`, 74, 74);
				setUrl4(s3Url);
			}

			setSaving4(false);
			setSaved4(false);
		} catch (e) {
			console.error(e);
		}
	};

	const resizeCompressToS3 = async (url, fileName, width, height) => {
		const resize_and_compress = app.functions().httpsCallable('resize_and_compress');
		try {
			const { data: s3URL } = await resize_and_compress({ url, fileName, width, height });
			return s3URL;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	const coinLatestNFT = coin.nft[coin.nft.length - 1];

	return (
		<div style={{ backgroundColor: 'WhiteSmoke' }} className="App">
			<SiteNav user={currentUser.email} tag={`Reviewing ${coin.name}`} showPages={true} />
			<div className="m-2">
				<h2>
					{coin.rank}. {coinLatestNFT.name}
				</h2>

				<h3>Base Image</h3>
				<p>Specs: full size 2000 x 2000px / min 300 dpi / uncropped / PNG with alpha (you should see light tan bg in the preview)</p>
				<div className="d-flex flex-row">
					<UploadImagePreview coinid={coinid} coinData={coin} setBaseImageParent={setFirebaseImageURL} colorType="base" />
					<UploadImagePreview coinid={coinid} coinData={coin} setBaseImageParent={setFirebaseImageURL} colorType="color2" />
					<UploadImagePreview coinid={coinid} coinData={coin} setBaseImageParent={setFirebaseImageURL} colorType="color3" />
					<UploadImagePreview coinid={coinid} coinData={coin} setBaseImageParent={setFirebaseImageURL} colorType="special" />
				</div>
				<div style={{ height: '6px', width: '100%', backgroundColor: 'purple' }}></div>
				{/* CROPPER1 */}
				<div className="crop-wrapper">
					<h3>Large Image</h3>
					<p>Character will go behind the text</p>
					<Button variant={cropped1PreviewUpdated ? 'primary' : 'warning'} className="m-2" onClick={showCropped1Image}>
						{cropped1PreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
					</Button>

					<Button variant={cropped1PreviewUpdated ? 'success' : 'warning'} className="m-2" onClick={handleUploadCropped1Image}>
						{cropped1PreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
					</Button>
					<span className="mx-2 font-weight-bold text-success">{savingLarge ? 'Saving...' : savedLarge ? '' : `DONE! ${urlLarge}`}</span>
					{savingLarge && <Spinner className="" animation="border" variant="primary" />}
					<div className="mx-2 mt-2 max-width" style={{ width: '256px' }}>
						<span>
							<strong>ZOOM SLIDER:</strong>
						</span>
						<RangeSlider variant="secondary" min={0.2} max={5} step={0.02} tooltipPlacement="top" value={zoom1} onChange={(changeEvent) => setZoom1(changeEvent.target.value)} />
					</div>
					<Row>
						<div className="crop-container">
							<Cropper
								image={firebaseImageURL}
								minZoom={0.2}
								maxZoom={5}
								zoomSpeed={0.02}
								restrictPosition={false}
								crop={crop1}
								zoom={zoom1}
								aspect={7 / 8}
								onCropChange={setCrop1}
								onCropComplete={onCrop1Complete}
								onZoomChange={setZoom1}
								objectFit="contain"
							/>
						</div>
						<div className="crop-preview-1">
							<img className="crop-preview-1" src={cropped1Image} alt="base" />
						</div>
					</Row>

					<div style={{ height: '6px', width: '100%', backgroundColor: 'purple' }}></div>

					<h3 className="pt-5">Preview Card Image</h3>
					<p>On the page with all the Ethemerals. Character will go behind the text.</p>

					<Button variant={cropped2PreviewUpdated ? 'primary' : 'warning'} className="m-2" onClick={showCropped2Image}>
						{cropped2PreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
					</Button>

					<Button variant={cropped2PreviewUpdated ? 'success' : 'warning'} className="m-2" onClick={handleUploadCropped2Image}>
						{cropped2PreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
					</Button>
					<span className="mx-2 font-weight-bold text-success">{saving2 ? 'Saving...' : saved2 ? '' : `DONE! ${url2}`}</span>
					{saving2 && <Spinner className="" animation="border" variant="primary" />}
					<div className="mx-2 mt-2 max-width" style={{ width: '200px' }}>
						<span>
							<strong>ZOOM SLIDER:</strong>
						</span>
						<RangeSlider variant="secondary" min={0.2} max={5} step={0.05} tooltipPlacement="top" value={zoom2} onChange={(changeEvent) => setZoom2(changeEvent.target.value)} />
					</div>
					<Row>
						<div className="crop-container2">
							<Cropper
								image={firebaseImageURL}
								minZoom={0.2}
								maxZoom={5}
								zoomSpeed={0.05}
								restrictPosition={false}
								crop={crop2}
								zoom={zoom2}
								aspect={2 / 3}
								onCropChange={setCrop2}
								onCropComplete={onCrop2Complete}
								onZoomChange={setZoom2}
								objectFit="contain"
								zoomWithScroll={false}
							/>
						</div>
						<div style={{ position: 'relative', left: 0, top: 0, background: 'DarkSlateGrey', width: '256px', height: '385px' }}>
							<img style={{ position: 'relative', top: 0, left: 0 }} className="crop-preview-2" src={cropped2Image} alt="base" />
							<img style={{ position: 'absolute', top: 0, left: 0 }} className="crop-preview-2" src="https://ethemerals-media.s3.amazonaws.com/firebase_card_preview.png" alt="base" />
						</div>
					</Row>
					<div style={{ height: '6px', width: '100%', backgroundColor: 'purple' }}></div>

					<h3 className="pt-5">Inventory Large Image</h3>
					<p>On the Inventory account tab, text in front, bottom black bar is semi transparent</p>

					<Button variant={cropped3PreviewUpdated ? 'primary' : 'warning'} className="m-2" onClick={showCropped3Image}>
						{cropped3PreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
					</Button>

					<Button variant={cropped3PreviewUpdated ? 'success' : 'warning'} className="m-2" onClick={handleUploadCropped3Image}>
						{cropped3PreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
					</Button>
					<span className="mx-2 font-weight-bold text-success">{saving3 ? 'Saving...' : saved3 ? '' : `DONE! ${url3}`}</span>
					{saving3 && <Spinner className="" animation="border" variant="primary" />}
					<div className="mx-2 mt-2 max-width" style={{ width: '200px' }}>
						<span>
							<strong>ZOOM SLIDER:</strong>
						</span>
						<RangeSlider variant="secondary" min={0.2} max={5} step={0.05} tooltipPlacement="top" value={zoom3} onChange={(changeEvent) => setZoom3(changeEvent.target.value)} />
					</div>
					<Row>
						<div className="crop-container3">
							<Cropper
								image={firebaseImageURL}
								minZoom={0.2}
								maxZoom={5}
								zoomSpeed={0.1}
								restrictPosition={false}
								crop={crop3}
								zoom={zoom3}
								aspect={97 / 28}
								onCropChange={setCrop3}
								onCropComplete={onCrop3Complete}
								onZoomChange={setZoom3}
								objectFit="contain"
								zoomWithScroll={false}
							/>
						</div>
						<div style={{ position: 'relative', left: 0, top: 0, width: '388px', height: '112px', background: 'DarkSlateGrey' }}>
							<img style={{ position: 'relative', top: 0, left: 0 }} className="crop-preview-3" src={cropped3Image} alt="base" />
							<img style={{ position: 'absolute', top: 0, left: 0 }} className="crop-preview-3" src="https://ethemerals-media.s3.amazonaws.com/firebase_card_inventory.png" alt="base" />
						</div>
					</Row>
					<div style={{ height: '6px', width: '100%', backgroundColor: 'purple' }}></div>

					<h3 className="pt-5">Thumbnail Image</h3>
					<p>small portrait on the Inventory account tab and battle current fighters</p>
					<Button variant={cropped4PreviewUpdated ? 'primary' : 'warning'} className="m-2" onClick={showCropped4Image}>
						{cropped4PreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
					</Button>

					<Button variant={cropped4PreviewUpdated ? 'success' : 'warning'} className="m-2" onClick={handleUploadCropped4Image}>
						{cropped4PreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
					</Button>
					<span className="mx-2 font-weight-bold text-success">{saving4 ? 'Saving...' : saved4 ? '' : `DONE! ${url4}`}</span>
					{saving4 && <Spinner className="" animation="border" variant="primary" />}
					<div className="mx-2 mt-2 max-width" style={{ width: '200px' }}>
						<span>
							<strong>ZOOM SLIDER:</strong>
						</span>
						<RangeSlider variant="secondary" min={3} max={6} step={0.05} tooltipPlacement="top" value={zoom4} onChange={(changeEvent) => setZoom4(changeEvent.target.value)} />
					</div>

					<Row>
						<div className="crop-container4">
							<Cropper
								image={firebaseImageURL}
								minZoom={3}
								maxZoom={6}
								zoomSpeed={0.1}
								restrictPosition={false}
								crop={crop4}
								zoom={zoom4}
								aspect={1 / 1}
								onCropChange={setCrop4}
								onCropComplete={onCrop4Complete}
								onZoomChange={setZoom4}
								objectFit="contain"
								zoomWithScroll={false}
							/>
						</div>
						<div style={{ position: 'relative', top: 0, left: 0, backgroundColor: 'DarkSlateGrey', width: '74px', height: '74px' }}>
							<img style={{ position: 'relative', top: 0, left: 0, backgroundColor: 'DarkSlateGrey', width: '74px', height: '74px' }} className="crop-preview-4" src={cropped4Image} alt="base" />
						</div>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default CoinReview;
